import request from "@/utils/request";

// LocationTransferRecord
export function locationTransferRecordList(params) {
  return request({ url: `/location_transfer_records/`, method: "get", params });
}

export function locationTransferRecordCreate(data) {
  return request({ url: `/location_transfer_records/`, method: "post", data });
}

export function locationTransferRecordVoid(data) {
  return request({ url: `/location_transfer_records/${data.id}/void/`, method: "post", data });
}


export function locationTransferRecordImport(data) {
  return request({
    url: "/location_transfer_records/import_data/",
    method: "post",
    data,
  });
}

export function locationTransferRecordTemplate(params) {
  return request({
    url: "/location_transfer_records/import_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}
