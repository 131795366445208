export const columns = [
  {
    title: "序号",
    dataIndex: "index",
    width: 60,
    fixed: "left",
    customRender: (value, item, index) => {
      return index + 1;
    },
  },
  {
    title: "客户",
    dataIndex: "client_name",
    fixed: "left",
    width: 100,
    customRender: (value, item, index) => {
      return item.client_item.name;
    },
  },
  {
    title: "产品编号",
    dataIndex: "material_number",
    customRender: (value, item, index) => {
      return item.material_item.number;
    },
  },
  {
    title: "产品名称",
    dataIndex: "material_name",
    customRender: (value, item, index) => {
      return item.material_item.name;
    },
  },
  {
    title: "移库数量",
    dataIndex: "transfer_quantity",
  },
  {
    title: "批次编号",
    dataIndex: "batch_number",
    customRender: (value, item, index) => {
      return item.batch_out_item.number;
    },
  },
  {
    title: "入库日期",
    dataIndex: "stock_in_date",
    customRender: (value, item, index) => {
      return item.batch_out_item.stock_in_date;
    },
  },
  {
    title: "状态",
    dataIndex: "status",
    key: "status",
    customRender: (value, item, index) => {
      return item.is_voided ? "已作废" : "完成";
    },
  },
  {
    title: "仓库",
    dataIndex: "warehouse_name",
    customRender: (value, item, index) => {
      return item.warehouse_item.name;
    },
  },
  {
    title: "源库位",
    dataIndex: "location_out_name",
    customRender: (value, item, index) => {
      return item.location_out_item.number;
    },
  },
  {
    title: "目标库位",
    dataIndex: "location_in_name",
    customRender: (value, item, index) => {
      return item.location_in_item.number;
    },
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
    key: "create_time",
    width: 160,
  },
  {
    title: "创建人",
    dataIndex: "creator_name",
    customRender: (value, item, index) => {
      return item.creator_item.name;
    },
  },
  {
    title: "操作",
    dataIndex: "action",
    key: "action",
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];
